import '@fontsource/kanit/400.css';
import '@fontsource/kanit/600.css';

import { ReactNode } from 'react';
import { IntlProvider } from 'react-intl';

import { ClerkApp } from '@clerk/remix';
import { rootAuthLoader } from '@clerk/remix/ssr.server';
import type { LinksFunction, LoaderFunctionArgs } from '@remix-run/node';
import {
  isRouteErrorResponse,
  json,
  Links,
  Meta,
  MetaFunction,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useRouteError,
} from '@remix-run/react';
import * as Sentry from '@sentry/remix';
import clsx from 'clsx';
import { PreventFlashOnWrongTheme, ThemeProvider } from 'remix-themes';
import { ExternalScripts } from 'remix-utils/external-scripts';

import { AppFooter } from './components/app-footer';
import { AppHeaderSimple } from './components/app-header';
import { FathomAnalytics } from './components/fathom-analytics';
import { useSafeTheme } from './components/theme-toggle';
import { SERVER_CONFIG } from './utils/config.server';
import { capturePageView } from './utils/posthog.server';
import { APP_TITLE } from './utils/seo';
import { themeSessionResolver } from './utils/theme-session.server';
import globalStylesheet from './globals.css?url';

export const links: LinksFunction = () => [
  { rel: 'stylesheet', href: globalStylesheet },
];

export const meta: MetaFunction = () => [
  { title: APP_TITLE, viewport: 'width=device-width,initial-scale=1' },
];

const Providers = ({
  children,
  fathomSiteId,
}: {
  children: ReactNode;
  fathomSiteId: string | null;
}) => {
  return (
    <>
      {fathomSiteId && <FathomAnalytics siteId={fathomSiteId} />}
      <IntlProvider locale="en" defaultLocale="en">
        {children}
      </IntlProvider>

      <ScrollRestoration />
      <Scripts />
    </>
  );
};

export const loader = (args: LoaderFunctionArgs) => {
  return rootAuthLoader(args, async ({ request }) => {
    const { getTheme } = await themeSessionResolver(request);

    const userId = request.auth.userId;

    capturePageView({ userId, request });

    return json({
      isSignedIn: Boolean(userId),
      theme: getTheme(),
      ENV: {
        FATHOM_SITE_ID: SERVER_CONFIG.FATHOM_SITE_ID,
      },
    });
  });
};

function AppBody({
  children,
  fathomSiteId,
}: {
  children: ReactNode;
  fathomSiteId: string | null;
}) {
  return (
    <body>
      <Providers fathomSiteId={fathomSiteId}>
        <div className="min-h-screen grid grid-rows-[auto_1fr_auto] gap-y-8 max-w-[1000px] mx-auto">
          {children}
          <AppFooter />
        </div>
      </Providers>
    </body>
  );
}

function AppHead({ children }: { children: ReactNode }) {
  return (
    <head>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <Meta />
      {children}
      <Links />
      <ExternalScripts />
    </head>
  );
}

function App() {
  const [theme] = useSafeTheme();
  const data = useLoaderData<typeof loader>();

  return (
    <html lang="en" className={clsx(theme)}>
      <AppHead>
        <PreventFlashOnWrongTheme ssrTheme={Boolean(data.theme)} />
      </AppHead>
      <AppBody fathomSiteId={data.ENV.FATHOM_SITE_ID}>
        <Outlet />
      </AppBody>
    </html>
  );
}

function AppWithProviders() {
  const data = useLoaderData<typeof loader>();
  return (
    <ThemeProvider specifiedTheme={data.theme} themeAction="/api/set-theme">
      <App />
    </ThemeProvider>
  );
}

export default ClerkApp(AppWithProviders);

export const ErrorBoundary = () => {
  const error = useRouteError();

  Sentry.captureRemixErrorBoundaryError(error);

  return (
    <html lang="en">
      <AppHead>
        <title>Oops!</title>
      </AppHead>
      <AppBody fathomSiteId={null}>
        <AppHeaderSimple />

        <main className="text-center text-lg">
          {isRouteErrorResponse(error)
            ? `${error.status} ${error.statusText}`
            : error instanceof Error
              ? error.message
              : 'Unknown Error'}
        </main>
      </AppBody>
    </html>
  );
};
