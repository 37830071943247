import { useEffect } from 'react';

import { useLocation } from '@remix-run/react';
import { load, trackPageview } from 'fathom-client';

import { isProduction } from '~/utils/config.client';

type Props = {
  siteId: string;
};

// https://tinloof.com/blog/how-to-integrate-fathom-analytics-into-your-remix-app
export function FathomAnalytics(props: Props) {
  const { siteId } = props;

  const location = useLocation();

  // Load the Fathom script on mount
  useEffect(() => {
    if (!siteId) {
      console.error('Fathom Site ID not found');
      return;
    }

    if (!isProduction) {
      console.debug(
        'Fathom Site ID found, but won’t track analytics in development',
      );
      return;
    }

    load(siteId);
  }, [siteId]);

  // Record a pageview when route changes
  useEffect(() => {
    trackPageview();
  }, [location.pathname, location.search]);

  return null;
}
